import React, { useEffect, useState } from "react";
import styles from "./Assessment.module.css";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { GET_ORGANIZATION } from "../../../graphql/queries/Organization";
import MaterialTable from "../../../components/UI/Table/MaterialTable";
import { Button } from "../../../components/UI/Form/Button/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useNavigate, useLocation } from "react-router-dom";
import * as routeConstant from "../../../common/RouteConstants";
import * as msgConstants from "../../../common/MessageConstants";
import SimpleBackdrop from "../../../components/UI/Layout/Backdrop/Backdrop";
import { setActiveFormStep } from "../../../services/Data";
import { CSET_URL, RA_URL } from "../../../config/index";
import { UPDATE_CSET_ASSESSMENT } from "../../../graphql/mutations/CSETAssessment";
import { GET_INDIVIDUAL } from "../../../graphql/queries/Individual";
import { GET_CSET_ASSESSMENT } from "../../../graphql/queries/CSETAssessment";
import { DialogBox } from "../../../components/UI/DialogBox/DialogBox";
import GetAppIcon from "@mui/icons-material/GetApp";
import { EXPSECONDS } from "../../../common/CSET";
import logout from "../../../containers/Auth/Logout/Logout";
import IconButton from "@mui/material/IconButton";
import Alert from "../../../components/UI/Alert/Alert";
import CloseIcon from "@mui/icons-material/Close";
import { saveAs } from "file-saver";
import { HISTORY } from "../../../graphql/mutations/AuditTrail";
import * as validations from "../../../common/validateRegex";
import { CREATE_CSET_ASSESSMENT_LINK,DELETE_CSET_ASSESSMENT_LINK } from "../../../graphql/mutations/CsetAssessmentLinks";
import { GET_CSET_ASSESSMENT_LINK } from "../../../graphql/queries/CsetAssessmentLink";
import Input from "../../../components/UI/Form/Input/Input";
import { useLazyQuery, useMutation } from "@apollo/client";
import { sentry_error_catch } from "../../../common/sentry_error_catch";
import { useErrorBoundary } from "react-error-boundary";
import * as Sentry from "@sentry/react";
import axios from 'axios';
import JSZip from 'jszip';
import { GET_ALL_DOCUMENTS } from "../../../graphql/queries/QuestionDocument";
export interface FormProps {
  name?: string;
  classname?: any;
  props?: any;
}

export const Assement: React.FC = (props: any) => {
  const envVariables = process.env;
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const pageName = "Assessment Listing Page ";
  const deleteIcon = "/icons/svg-icon/delete.svg";
  const serverUrl = envVariables.REACT_APP_SERVER_URL;
  const [showBackDrop, setShowBackDrop] = useState(true);
  const history = useNavigate();
  const RAuser: any = JSON.parse(sessionStorage.getItem("ra_user") || "");
  const [newData, setNewData] = useState([]);
  const [mainComponent] = useState<any>();
  const [elementData] = useState<any>();
  const [processComplete] = useState<any>(false);
  const [param, setParam] = useState<any>(
    location?.state[Object.keys(location?.state)[0]]
  );
  const authToken = sessionStorage.getItem("ra_token") || logout();
  const RAcontact = sessionStorage.getItem("ra_contact") || logout();
  const contact = JSON.parse(RAcontact);
  const [pgPartnerUserId, setPgPartnerUserId] = useState<any>();
  const [pgPartnerId, setPgPartnerId] = useState<any>();
  const [updateAssessement] = useMutation(UPDATE_CSET_ASSESSMENT);
  const [deleteAssessementLink] = useMutation(DELETE_CSET_ASSESSMENT_LINK);
  const [rowDataObj, setRowDataObj] = useState<any>({});
  const [openDialogBox, setOpenDialogBox] = useState<boolean>(false);
  const [openMailDialogBox, setOpenMailDialogBox] = useState<boolean>(false);
  const [openLinkExpiredDialogBox, setOpenLinkExpiredDialogBox] = useState<boolean>(false);
  const [email,setEmail] = useState<string>("");
  const [createAssessmentLink] = useMutation(CREATE_CSET_ASSESSMENT_LINK);
  const [assessmentList, setAssessmentList] = useState<any>();
  const [partnerTimeZone, setPartnerTimeZone] = useState<any>("US/Eastern");
  const [assessmentDocData,setAssessmentDocData] = useState<any>()
  const [formState, setFormState] = useState({
    isSuccess: false,
    isUpdate: false,
    isFailed: false,
    isDelete: false,
    isCreateLinkFailed: false,
    errMessage: "",
  });
  const [getAllDocuments] = useLazyQuery(GET_ALL_DOCUMENTS);

  useEffect(() => {
    setParam(location?.state[Object.keys(location?.state)[0]]);
   
  }, []);

  const moment = require("moment-timezone");
  //table
  const column = [
    { title: "Assessment Name", key: "AssessmentName", sorting: "asc" },
    { title: "Client Name", key: "CreatorName" },
    { title: "Created on", key: "created_at" },
    { title: "Submitted on", key: "submitted_at" },
    { title: "Status", key: "asmt_staus" },
    { title: "Assessment Type", key: "assessment_type" },
    { title: "Assessment Subtype", key: "assessment_subtype" },
    {
      key: "Download",
      title: "",
      icon: <GetAppIcon className={styles.iconStyle} />,
      tooltip: "Download",
      displayIcon: (rowData: any) => {
        return rowData?.asmt_staus === "Done";
      },
    },
    {
      key: "Edit",
      title: "",
      icon: (
        <img
          className={styles.EditIcon}
          src={`${process.env.PUBLIC_URL}/icons/svg-icon/edit.svg`}
          alt="edit icon"
        />
      ),
      tooltip: "Edit Assessment",
      displayIcon: (rowData: any) => {
        return rowData?.asmt_staus === "In Progress";
      },
    },

    {
      key: "Delete",
      title: "",
      icon: (
        <img
          className={styles.EditIcon}
          src={process.env.PUBLIC_URL + deleteIcon}
          alt="delete icon"
        />
      ),
      tooltip: "Delete Assessment",
      displayIcon: (rowData: any) => true,
    },
    {
      key: "link",
      title: "",
      icon: (rowData:any) => {
        return rowData?.ccAssessmentLink ? "Copy Link" : "Create Link"
      },
      tooltip: "Link",
      displayIcon: (rowData: any) => {
        return rowData?.asmt_staus === "In Progress";
      },
    },
    {
      key: "linkExpired",
      title: "",
      icon: "Deactivate Link",
      tooltip: "Link Expired",
      displayIcon: (rowData: any) => {
        return rowData?.asmt_staus === "In Progress" && (rowData?.ccAssessmentLink);
      },
    },
    // {
    //   key: "View",
    //   title: "",
    //   icon: <VisibilityIcon />,
    //   tooltip: "View Assessment",
    // },
  ];

  const headerObj = {
    "Content-Type": "application/json",
    Authorization: authToken,
  };

  useEffect(() => {
    const controller = new AbortController();
    if (param) {
      getAssessment({
        variables: {
          where: {
            contact_id: param?.clientInfo?.clientId,
          },
          sort: "id	:desc",
        },
      });
      getCsetAssessmentLink({
        variables:{
          where:{
            client_id : param?.clientInfo?.clientId
          },
          sort: "id	:desc",
          limit:100,
        }
      })
    } else {
      history(routeConstant.CLIENT);
    }
    getAssessmentForUser();
    getIndividual({
      variables: {
        where: { partner_id_null: false, contact_id: contact.id },
      },
    });
    return () => controller.abort();
  }, [param]);

  const [auditTrail] = useMutation(HISTORY);

  let retryAttempt = false;
  const getAssessmentForUser = async () => {
    try {
      await fetch(`${CSET_URL}api/assessmentsforuser`, {
        method: "GET",
        headers: headerObj,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data",data)
          if (Array.isArray(data)) {
            const sortedData = data.sort((a: any, b: any) =>
              a.assessmentId < b.assessmentId ? 1 : -1
            );
            setAssessmentList(sortedData);
            if (data.length === 0) {
              setShowBackDrop(false);
            }
          } else {
            console.log("data is not array");
            if(!retryAttempt){
              retryAttempt = true;
              getAssessmentForUser()
            }else{
              console.log('retry attempt failed, data is still not array')
              setFormState((formState:any) => ({
                ...formState,
                isSuccess: false,
                isUpdate: false,
                isDelete: false,
                isFailed: true,
                errMessage: msgConstants.ERROR_MESSAGE,
              }))
            }
            setShowBackDrop(false);
          }
        })
        .catch((err:any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } catch (err:any) {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };

  // Second Query..
  const [getIndividual, { data: iData }] = useLazyQuery(GET_INDIVIDUAL, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onCompleted: (data) => {
      setPgPartnerUserId(data.individuals[0].pg_user_id);
      getOrganization({
        variables: {
          where: {
            subtype: "Partner",
            contact_id: data.individuals[0].partner_id.id,
          },
        },
      });
    },
    onError: (err:any) => {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    },
  });

  const [getOrganization, { data: dataOrg }] = useLazyQuery(GET_ORGANIZATION, {
    fetchPolicy: msgConstants.networkFetchPolicy,
    onError: (err:any) => {sentry_error_catch(err,setShowBackDrop,setFormState)},
    onCompleted: (data: any) => {
      setPgPartnerId(dataOrg?.organizations[0].pg_id);
      setPartnerTimeZone(dataOrg?.organizations[0].timezone);
    },
  });
  const [getAssessment, { data: assessmentData }] = useLazyQuery(
    GET_CSET_ASSESSMENT,
    {
      fetchPolicy: "network-only",
      onError: (err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      },
    }
  );

  const [getCsetAssessmentLink, { data: assessmentLinkData }] = useLazyQuery(
    GET_CSET_ASSESSMENT_LINK,
    {
      fetchPolicy: "network-only",
      onError: (err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState)
      },
    }
  );

  function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue((value) => value + 1); // update state to force render
    // A function that increments 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
  }

  useEffect(() => {
    if (assessmentList) {
      createTableDataObject();
    }
  }, [assessmentData, assessmentList,assessmentLinkData, dataOrg, partnerTimeZone !== ""]);

  const forceUpdate = useForceUpdate();

  /*  Below Function Creates an Array of Objects which is then returned to Material Table COmponent 
      to Display Listing of All the Assessments.
    It takes Data from two API's called in getAssessmentForUser and  getAssessment.
*/
  const createTableDataObject = () => {
    const arr: any = [];
    if (assessmentData !== undefined) {
      assessmentData.csetAssessments.forEach((element: any, index: any) => {
        const obj: any = {};
        if (element?.assessment_name !== null) {
          obj["Id"] = element.assessment_id;
          obj["AssessmentName"] =
            element?.assessment_name?.indexOf("%") > -1
              ? element?.assessment_name?.slice(
                  0,
                  element?.assessment_name?.indexOf("%")
                )
              : element?.assessment_name !== null
              ? element?.assessment_name
              : "";
          obj["assessment_type"] = element?.cset_assessment_type?.group_Title ? element?.cset_assessment_type?.group_Title : element?.assessment_type;
          obj["assessment_subtype"] = element?.cset_assessment_type?.name ? element?.cset_assessment_type?.name : "-"
          let updateDate = null;
          let createDate = null;
          let isallowdownload = false;
          let reportGeneration = false;
          let toDelete = false;
          if (assessmentList) {
            for (const i in assessmentList) {
              obj["CreatorName"] = param?.clientInfo?.name;
              obj["UserId"] = assessmentList[i].userId;
            }
          }
          if (element.created_at) {
            createDate = moment(element.created_at)
              .tz("US/Eastern")
              .format("MM/DD/YYYY hh:mm a");
          }
          if (element.updated_at) {
            updateDate = moment(element.updated_at)
              .tz("US/Eastern")
              .format("MM/DD/YYYY hh:mm a");
          }
          isallowdownload = element.allow_download;
          if (element.deleteData) {
            toDelete = element.deleteData.deleteAssessment[0].delete;
          }
          obj["ccAssessmentInfo"] = {
            assessment_id: element.assessment_id,
            assessment_type: element.assessment_type,
            cset_firm_id: element.cset_firm_id,
            id: element.id,
            assessment_Document:
              element.assessment_document && element.assessment_document[0]
                ? element.assessment_document[0].url
                : "",
            allow_download: element.allow_download,
            report_generation: element.report_generation,
          };
          reportGeneration = element.report_generation;
          if(assessmentLinkData){
            for(const i in assessmentLinkData.csetAssessmentLinks){
              if(assessmentLinkData.csetAssessmentLinks[i].cset_assessment_id ===  Number(element.id)){
                obj["ccAssessmentLink"] = {
                  assessment_link_id : assessmentLinkData.csetAssessmentLinks[i].id,
                  assessment_link : assessmentLinkData.csetAssessmentLinks[i].shared_link,
                  assessment_token : assessmentLinkData.csetAssessmentLinks[i].token,
                  password : assessmentLinkData.csetAssessmentLinks[i].password,
                  assessment : assessmentLinkData.csetAssessmentLinks[i].cset_assessment_id,
                }
 
              }
            }
          }
          obj["created_at"] =
            createDate === null
              ? "-"
              : moment(createDate).format(msgConstants.TIME_FORMAT);
          if (isallowdownload === true) {
            obj["submitted_at"] = moment(updateDate).format(
              msgConstants.TIME_FORMAT
            );
            obj["asmt_staus"] = "Done";
          } else {
            obj["submitted_at"] = " - ";
            obj["asmt_staus"] = "In Progress";
          }
          if (reportGeneration === true) {
            obj["asmt_staus"] = "Generating Report";
          }
          obj["to_Delete"] = toDelete;
          arr.push(obj);
        }
      });
      const filteredData = arr.filter((x: any) => x.to_Delete === false);
      setNewData(filteredData);
      console.log("filteredData",filteredData);
      setShowBackDrop(false);
      forceUpdate();
    }
  };

  const enableBackdrop = () => {
    if (processComplete) {
      return true;
    } else {
      return false;
    }
  };

  const onRowClick = async (rowData: any, val: any) => {
    handleAlertClose();
    setShowBackDrop(true);
    setRowDataObj(rowData);
    if (val === "Edit") {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData?.individuals[0]?.id? iData.individuals[0].id : param?.individualId,
            organization: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
            action: {
              message: `User Clicked on Edit Button.`,
              data: rowData,
            },
            flowtype: null,
            page: pageName,
            client: param.clientInfo.clientOrgId,
          },
        },
      }).then((res: any) => {
        navigateTo(rowData, "Edit");
      }).catch((err: any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    }
    if (val === "Delete") {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData?.individuals[0]?.id  ? iData.individuals[0].id : param?.individualId,
            organization: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
            action: {
              message: `User Clicked on Delete Button.`,
              data: rowData,
            },
            flowtype: null,
            page: pageName,
            client: param.clientInfo.clientOrgId,
          },
        },
      }).then((res: any) => {
        setOpenDialogBox(true);
      }).catch((err: any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    }
    if (val === "View") {
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData?.individuals[0]?.id ? iData.individuals[0].id : param?.individualId,
            organization: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
            action: {
              message: `User Clicked on View Button.`,
              data: rowData,
            },
            flowtype: null,
            page: pageName,
            client: param.clientInfo.clientOrgId,
          },
        },
      }).then((res: any) => {
        navigateTo(rowData, "View");
      }).catch((err: any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    }
    if (val === "Download") {
      onDownload(rowData);
    }
    if(val === "link"){
      if(rowData?.ccAssessmentLink ){
        setShowBackDrop(false);
        const copyToClipBoardText = `The Link is ${rowData["ccAssessmentLink"]["assessment_link"]} and it's password is ${rowData["ccAssessmentLink"]["password"]}`;
        navigator.clipboard.writeText(copyToClipBoardText).then(() => {
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            isCreateLinkFailed: false,
            errMessage: "Link copied to clipboard successfully.",
          }));
        })
        .catch((err:any) => {
          Sentry.captureException(err)
          setShowBackDrop(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: true,
            isCreateLinkFailed: false,
            errMessage: "Could not copy Link to clipboard.",
          }));
        });
      }else{
        setShowBackDrop(false);
        setOpenMailDialogBox(true);
      }
    }
    if (val === "linkExpired") {
      setShowBackDrop(false);
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: param.individualId,
            organization: param.partnerOrganizationId,
            cset_assessment: rowData?.ccAssessmentInfo?.id,
            action: {
              message: `User Clicked on Deactivate Button.`,
              data: rowData,
            },
            flowtype: null,
            page: pageName,
            client: param.clientOrgId,
          },
        },
      }).then((res: any) => {
        setOpenLinkExpiredDialogBox(true);
      }).catch((err:any) => {
        Sentry.captureException(err);
      });
    }
  };

  const onLinkExpired = () => {
    setOpenLinkExpiredDialogBox(false);
    if (rowDataObj?.ccAssessmentLink) {
      setShowBackDrop(true);
      deleteAssessementLink({
        variables:{
          id: rowDataObj.ccAssessmentLink.assessment_link_id,
        }
      }).then((data:any) => {
        setShowBackDrop(false);
        console.log("data",data);
        const responseData = data?.data?.deleteCsetAssessmentLink;
        if(responseData && responseData.csetAssessmentLink){
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            isCreateLinkFailed: false,
            errMessage: "The Link has been Deactivate Successfully!.",
          }));
          getCsetAssessmentLink({
            variables:{
              where:{
                client_id : param?.clientInfo?.clientId
              },
              sort: "id	:desc",
              limit:100,
            }
          })
        }
      }).catch((err:any) => {
        Sentry.captureException(err)
        setShowBackDrop(false);
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          isCreateLinkFailed: false,
          errMessage: "Could not Deactivate the Link.",
        }));
      })
    }
  };
  const onDownload = async (rowData: any) => {
    try{
      if (rowData?.ccAssessmentInfo?.assessment_Document !== "") {
        setShowBackDrop(true);
        if (rowData?.ccAssessmentInfo?.assessment_Document) {
          const questionData = await getAllDocuments({
            variables: {
              featureType: "Document",
              assId: +rowData.ccAssessmentInfo.id,
            },
          }).then((data:any)=>{
            return data
          }).catch((error)=>{
            console.log(error)
          })
          const lawUrl = rowData?.ccAssessmentInfo?.assessment_Document.slice(1);
          const DocUrl = serverUrl + lawUrl;
          const reportResponse = await fetch(DocUrl, { method: "GET" });
          const reportBlob = await reportResponse.blob();
  
          const reportZip = await JSZip.loadAsync(reportBlob);
          if(questionData?.data?.questionDocuments.length > 0){
            const documentLinks = questionData.data.questionDocuments.flatMap((doc: any) =>
              doc.document_id.map((file: any) => ({
                url: file?.url?.replace(/^\//, ''), 
                name: file?.name  
              }))
            );
    
            const uploadedFilesFolder = reportZip.folder("Uploaded files");
    
            const downloadDocuments = documentLinks.map(async (file: { url: string, name: string }, index: number) => {
              const DocUrl = `${serverUrl}${file.url}`;
              const res = await axios.get(DocUrl, { responseType: 'blob' });    
              await uploadedFilesFolder?.file(file.name, res.data);
            });
    
            await Promise.all(downloadDocuments);
          }else{
            console.log('no documents')
          }
          const finalZip = await reportZip.generateAsync({ type: "blob" });
          let zipFileName = ''
          if(param.assessmentInfo){
            zipFileName = param.assessmentInfo.assessmentName.indexOf("%") > -1
            ? param.assessmentInfo.assessmentName.slice(0, param.assessmentInfo.assessmentName.indexOf("%"))
            : param.assessmentInfo.assessmentName + ".zip";
          }else{
            zipFileName = rowData.AssessmentName.indexOf("%") > -1
            ? rowData.AssessmentName.slice(0, rowData.AssessmentName.indexOf("%"))
            : rowData.AssessmentName + ".zip";
          }
            saveAs(finalZip, zipFileName);
            setShowBackDrop(false);
        }
      }
    }catch(error){
      showBoundary(error);
      setShowBackDrop(false);
    }
  };
  console.log("assessment listing param,pg partner Id",param.pgPartnerId);
  console.log("assessment listing state,pg partner Id",pgPartnerId);
  console.log("param", param);
  const createNewAssessment = async () => {
    try {
      const val = {
        assessmentInfo: {},
        clientInfo: param.clientInfo,
        csetUserInfo: param.clientInfo,
        ccAssessmentInfo: {},
        pgPartnerId: param.pgPartnerId ? param.pgPartnerId : pgPartnerId,
        pgPartnerUserId: param.pgPartnerUserId
          ? param.pgPartnerUserId
          : pgPartnerUserId,
        flowType: {
          type: "",
        },
        individualId: param.individualId,
        partnerOrganizationId: param.partnerOrganizationId,
        clientOrgId: param.clientOrgId,
      };
      setActiveFormStep(0);
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData?.individuals[0]?.id ? iData.individuals[0].id : param?.individualId,
            organization: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
            action: {
              message: `User Clicked on Create Assessment Button.`,
            },
            flowtype: null,
            page: "Assessment Listing Page",
            client: param.clientOrgId,
          },
        },
      })
        .then((res: any) => {
          history(routeConstant.ASSESSMENT_TYPE, { state: { val } });
        })
        .catch((err: any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } catch (err:any) {
        sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };

  const confirmDelete = async () => {
    closeDialogBox();
    setShowBackDrop(true);
    try {
      const deleteObj = {
        deleteAssessment: [{ delete: true, partnerUserId: RAuser?.user?.id }],
      };
      updateAssessement({
        variables: {
          id: rowDataObj.ccAssessmentInfo.id,
          assessment_type: rowDataObj.ccAssessmentInfo.assessment_type,
          deleteData: deleteObj,
        },
      })
        .then((response: any) => {
          setOpenDialogBox(false);
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            errMessage: msgConstants.DELETE_MSG,
          }));
          getIndividual({
            variables: {
              where: { partner_id_null: false, contact_id: contact.id },
            },
          });
          getAssessment({
            variables: {
              where: {
                contact_id: param?.clientInfo?.clientId,
              },
              sort: "id	:desc",
            },
          });
          getAssessmentForUser();
        })
        .catch((err: any) => {
          sentry_error_catch(err,setShowBackDrop,setFormState)
        });
    } catch (err:any) {
      sentry_error_catch(err,setShowBackDrop,setFormState)
    }
  };
  
  const emailChangeHandler = (event:any) => {
    const value = event.target.value.trimStart();
    setEmail(value);
  }

  const emailCancelHandler = () => {
    setOpenMailDialogBox(false);
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      errMessage: "",
    }));
    setEmail("");
  }

  const closeDialogBox = () => {
    setShowBackDrop(false);
    setOpenDialogBox(false);
  };

  //to get token
  const getTokenForAssessment = async (val: any) => {
    await fetch(
      `${CSET_URL}api/auth/token?assessmentId=${val.assessmentInfo.id}&expSeconds=${EXPSECONDS}`,
      {
        method: "GET",
        headers: headerObj,
      }
    )
      .then((response) => response.json())
      .then((data) => {
        sessionStorage.setItem("ra_token", data.token);
        val["from"] = "view";
        if (val.flowType.type === "CMMC2") {
          history(routeConstant.CMMC2_REPORTS, { state: { val } });
        }
        if (val.flowType.type === "CRR") {
          history(routeConstant.CRR_REPORTS, { state: { val } });
        }
        if (val.flowType.type === "RRA") {
          history(routeConstant.RRA_REPORTS, { state: { val } });
        }
        if (val.flowType.type === "Standard") {
          history(routeConstant.REPORTS, { state: { val } });
        }
        if (val.flowType.type === "Custom") {
          history(routeConstant.REPORTS, { state: { val } });
        }
      })
      .catch((err:any) => {
        sentry_error_catch(err,setShowBackDrop,setFormState,"This Assessment Cannot be Viewed.")
      });
  };

  const navigateTo = async (data: any, type: any) => {
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      ccAssessmentInfo: data.ccAssessmentInfo,
      assessmentInfo: {
        id: data.Id,
        assessmentName: data.AssessmentName,
        CreatorName: data.CreatorName,
        UserId: data.UserId,
      },
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
      flowType: {
        type: data.ccAssessmentInfo?.assessment_type,
      },
      individualId: iData?.individuals[0]?.id? iData.individuals[0].id : param?.individualId,
      partnerOrganizationId: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
      clientOrgId: param.clientInfo.clientOrgId,
    };
    if (type === "Edit" && data.UserId) {
      history(routeConstant.ASSESSMENT_INFO, { state: { val } });
    }
    if (type === "View") {
      getTokenForAssessment(val);
    }
  };

  const backToList = () => {
    try{
    setShowBackDrop(true);
    history(routeConstant.CLIENT);
    }catch(err){
      showBoundary(err);
    }
  };

  const handleAlertClose = () => {
    setFormState((formState) => ({
      ...formState,
      isSuccess: false,
      isUpdate: false,
      isDelete: false,
      isFailed: false,
      isCreateLinkFailed: false,
      errMessage: "",
    }));
  };

  const createLinkHandler = () => {
    try{
    const val: any = {
      clientInfo: param.clientInfo,
      csetUserInfo: param.csetUserInfo,
      ccAssessmentInfo: rowDataObj.ccAssessmentInfo,
      assessmentInfo: {
        id: rowDataObj.Id,
        assessmentName: rowDataObj.AssessmentName,
        CreatorName: rowDataObj.CreatorName,
        UserId: rowDataObj.UserId,
      },
      pgPartnerId: param.pgPartnerId,
      pgPartnerUserId: param.pgPartnerUserId,
      flowType: {
        type: rowDataObj.ccAssessmentInfo?.assessment_type,
      },
      individualId: iData?.individuals[0]?.id ? iData.individuals[0].id : param?.individualId,
      partnerOrganizationId: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
      clientOrgId: param.clientInfo.clientOrgId,
    };
    if (!validations.EMAIL_REGEX.test(email)) {
      setFormState((formState) => ({
        ...formState,
        isSuccess: false,
        isUpdate: false,
        isDelete: false,
        isFailed: false,
        isCreateLinkFailed:true,
        errMessage: "Please enter valid email address.",
      }));
    }else{ 
      auditTrail({
        variables: {
          auditTrailInput: {
            individual: iData?.individuals[0]?.id ? iData.individuals[0].id : param?.individualId,
            organization: dataOrg?.organizations[0]?.id ? dataOrg.organizations[0].id : param?.partnerOrganizationId,
            cset_assessment: rowDataObj.ccAssessmentInfo.id,
            action: {
              message: `User Clicked on Create Link Button.`,
            },
            flowtype: null,
            page: "Assessment Listing Page",
            client: param.clientOrgId,
          },
        },
      }).catch((error: any) => {
        Sentry.captureException(error);
      }); 
      setShowBackDrop(true);
      const token = sessionStorage.getItem("ra_session");
      const link = `${RA_URL}assessmentInfo/${token}/${rowDataObj.ccAssessmentInfo.id}`;
      console.log("Generate Link",link,token);
      createAssessmentLink({
        variables:{
          input:{
            "partner_id":param.clientInfo.partnerId,
            "assessment_id": rowDataObj.ccAssessmentInfo.id,
            "token": token,
            "client_id": param.clientInfo.clientId,
            "user_id": RAuser?.user?.id,
            "link": link,
            "email": email,
            "assessment_data": val
          }
        }
      }).then((data:any) => {
        console.log("data",data);
        setShowBackDrop(false);
        if(data.data.createUpdateSharedLink.message === "Assessment Link Created Successfully"){
          emailCancelHandler();
          setFormState((formState) => ({
            ...formState,
            isSuccess: true,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            isCreateLinkFailed: false,
            errMessage: `The assessment link has been successfully generated and emailed.`,
          }));
          getCsetAssessmentLink({
            variables:{
              where:{
                client_id : param?.clientInfo?.clientId
              },
              sort: "id	:desc",
              limit:100,
            }
          })
        }else{
          setFormState((formState) => ({
            ...formState,
            isSuccess: false,
            isUpdate: false,
            isDelete: false,
            isFailed: false,
            isCreateLinkFailed: true,
            errMessage: `${data.data.createUpdateSharedLink.message} `,
          }));
        }
      }).catch((err:any) => {
        setShowBackDrop(false);
        setOpenMailDialogBox(false);
        setEmail("");
        Sentry.captureException(err)
        setFormState((formState) => ({
          ...formState,
          isSuccess: false,
          isUpdate: false,
          isDelete: false,
          isFailed: true,
          isCreateLinkFailed: false,
          errMessage: msgConstants.ERROR_MESSAGE,
        }));
      })
    }
   }catch(err){
    showBoundary(err)
   }
  }


  return (
    <React.Fragment>
      <CssBaseline />
      {showBackDrop ? <SimpleBackdrop /> : null}
      <DialogBox
        open={openDialogBox}
        handleOk={confirmDelete}
        handleCancel={closeDialogBox}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext}>
          <p>Are you sure you want to delete {rowDataObj.AssessmentName}?</p>
        </div>
      </DialogBox>
      <DialogBox
        open={openLinkExpiredDialogBox}
        handleOk={onLinkExpired}
        handleCancel={() => {
          setOpenLinkExpiredDialogBox(false);
        }}
        buttonOk={"Yes"}
        buttonCancel={"No"}
        classes={{
          root: styles.MainOfficeDialogRoot,
          container: styles.MainOfficeDialogboxContainer,
          paper: styles.MainOfficeDialogboxPaper,
          scrollPaper: styles.MainOfficeScrollPaper,
        }}
      >
        <div className={styles.DialogBoxTitle}>
          <Typography component="h1" variant="h1">
            Please Confirm
          </Typography>
        </div>
        <div className={styles.DialogBoxContext} style={{paddingTop: "10px"}}>
          <p style={{fontSize: "15px"}}><b>Are you sure you want to Deactivate the '{rowDataObj.AssessmentName}' 
          Assessment 's Link ?</b></p>
        </div>
      </DialogBox>
      <DialogBox
          open={openMailDialogBox}
          handleOk={createLinkHandler}
          handleCancel={emailCancelHandler}
          buttonOk={"Send"}
          buttonCancel={"Cancel"}
          classes={{
            root: styles.MainOfficeDialogRoot,
            container: styles.MainOfficeDialogboxContainer,
            paper: styles.MainOfficeMailDialogboxPaper,
            scrollPaper: styles.MainOfficeScrollPaper,
          }}
          disabled={email.length <= 0}
        >
          {showBackDrop ? <SimpleBackdrop /> : null}
          <div className={styles.DialogBoxTitle}>
            <Typography component="h1" variant="h1">
              {"Email Box"}
            </Typography>
          </div>
          <div className={styles.DialogBoxContext}>
          {formState.isCreateLinkFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
            <Grid container>
              <Grid item xs={4} classes={{ item: styles.MuiGridItem }}>
                <Typography component="h3" variant="h3"> Email :</Typography>
              </Grid>
              <Grid
                item
                xs={8}
                classes={{ item: styles.ReactInput1 }}
              >
                <Input
                  id="assessmentLinkSendMail"
                  name="assessmentLinkSendMail" 
                  value = {email}
                  Autocomplete="off"
                  onChange = {emailChangeHandler}
                >
                  E-mail
                </Input>
              </Grid>
            </Grid>
          </div>
        </DialogBox>
      <Grid>
        <Typography component="h5" variant="h1">
          Risk Assessment List
        </Typography>
        <Grid container>
          <Grid item xs={12} sm={8} className={styles.FilterWrap}></Grid>
          <Grid item xs={12} sm={4} className={styles.FilterAddWrap}>
            <div className={styles.ButtonGroup2}>
              <Button
                id="cancel-button"
                className={styles.BackToButton}
                variant={"contained"}
                onClick={backToList}
                color="secondary"
                data-testid="cancel-button"
              >
                <img
                  src={`${process.env.PUBLIC_URL}/icons/svg-icon/back-list.svg`}
                  alt="user icon"
                />
                &nbsp; Back to Home
              </Button>
              <Button
                id="create-assessment"
                color="primary"
                variant="contained"
                onClick={createNewAssessment}
              >
                <AddCircleIcon className={styles.EditIcon} />
                &nbsp; New assessment
              </Button>
            </div>
          </Grid>
        </Grid>
        <Paper className={styles.paper}>
          {formState.isSuccess ? (
            <Alert
              severity="success"
              action={
                <IconButton
                  id="close"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              <strong>{formState.errMessage}</strong>
            </Alert>
          ) : null}
          {formState.isFailed ? (
            <Alert
              severity="error"
              action={
                <IconButton
                  id="close"
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleAlertClose}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {msgConstants.FAILED}&nbsp;{formState.errMessage}
            </Alert>
          ) : null}
          <div className={styles.ScrollTable}>
            {newData.length !== 0 ? (
              <MaterialTable
                data={newData}
                columns={column}
                table={"Assessment"}
                pageSize={10}
                onButtonClick={onRowClick}
              />
            ) : enableBackdrop() ? null : (
              <Typography component="h5" variant="h3">
                No Assessments to be Displayed.
              </Typography>
            )}
          </div>
          {mainComponent ? mainComponent : null}
          {elementData ? elementData : null}
        </Paper>
      </Grid>
    </React.Fragment>
  );
};

export default Assement;
